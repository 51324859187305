@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: "Nunito", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    background: #333;
}

.App {
    margin: 4rem auto;
}

.App > h1 {
    text-align: center;
    margin: 1rem 0;
}

.Card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid white;
}

.Card h1 {
    font-size: 20px;
}

.Card span {
    font-size: 15px;
}

.Card--task {
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid white;
}

.task {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Card--habit-group {
    width: 30%;
}

.Card--text h1 {
    color: #67b55e;
}
.Card--add-task {
}
.task h2 {
    font-size: 14px;
    font-weight: normal;
}

.task h3 {
    font-size: 14px;
    font-weight: normal;
    text-align: left;
}
.task h1 {
    margin: 0;
    padding: 0;
    font-size: 20px;
}

.Card--text h3 {
    font-size: 12px;
}

.Card--button button {
    padding: 0.4rem 1rem;
    border-radius: 20px;
    cursor: pointer;
}

.Card--button__delete {
    border: 1px solid #ca0000;
    color: #ca0000;
}

.Card--button__done {
    border: 1px solid #00aa69;
    color: #00aa69;
    margin-right: 1rem;
}

.Form {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
}

.Form--values {
    width: 100%;
}

.Form > div {
    display: flex;
}

.Form input {
    background: #f5f6f7;
    padding: 0.5rem 1rem;
    border: 1px solid #ff9900;
    border-radius: 10px;
    display: block;
    margin: 0.3rem 1rem 0 0;
}

.Form select {
    background: #f5f6f7;
    padding: 0.5rem 1rem;
    border: 1px solid #ff9900;
    border-radius: 10px;
    display: block;
    margin: 0.3rem 1rem 0 0;
}

.Form react-datepicker-wrapper {
    background: #f5f6f7;
    padding: 0.5rem 1rem;
    border: 1px solid #ff9900;
    border-radius: 10px;
    display: block;
    margin: 0.3rem 1rem 0 0;
}

.Form label {
}

.Form--date {
}
.Form--habit {
}
.Form--notes {
    width: 800px;
}
.Form--notes input {
    width: 100%;
}
.Form button {
    background: #67b55e;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    border: none;
}

.line-through {
    text-decoration: line-through;
    color: #777 !important;
}

.hide-button {
    display: none;
}

.highlight {
    background-color: blue;
    color: red;
}

.calendar-tile {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected-date {
    margin-top: 16px;
}

.selected-date h3 {
    margin-bottom: 8px;
}

.cell {
    background-color: none;
}

.cell-color-1 {
    background-color: #b2d1b2; /* Honeydew */
}

.cell-color-2 {
    background-color: #99bf99; /* Light Green */
}

.cell-color-3 {
    background-color: #7ba67b; /* Light Green */
}

.cell-color-4 {
    background-color: #669166; /* Medium Light Green */
}

.cell-color-5 {
    background-color: #487548; /* Medium Green */
}

.cell-color-6 {
    background-color: #376637; /* Medium Green */
}

.cell-color-7 {
    background-color: #2b5c2b; /* Medium Dark Green */
}

.cell-color-8 {
    background-color: #2b4e20; /* Dark Green */
}

.cell-color-9 {
    background-color: #0f2602; /* Dark Green */
}

.cell-color-10 {
    background-color: #001900; /* Darkest Green */
}
.react-calendar {
    background: none;
    text-color: white !important;
}

.react-calendar button {
    color: white !important;
}

.add-task-day-list {
    display: block;
    margin-left: auto;
}

.Card--add-task > div {
    flex-direction: column;
    width: 800px;
}
.Card--task-notes input {
    width: 100%;
}

.calendar-edit {
    display: flex;
    justify-content: space-between;
}

.task-list li {
    margin-bottom: 10px;
    border-bottom: 1px solid white;
}
.calendar {
    margin-left: 10px;
}
